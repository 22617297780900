<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 3H12C7.02944 3 3 7.02944 3 12V12V12C3 16.9706 7.02944 21 12 21V21C16.9706 21 21 16.9706 21 12V8.0625"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 12L11 14L16 9"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
